<template>
  <div class="row mt-md-0 mt-5">

    <!-- Form -->
    <div class="col-md-12">
      <Card :title="'Tambah Poliklinik'">
        <template v-slot:body>
          <Form
            :form="form"
            :route="'polyclinics'"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/polyclinics/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      form: {
        name: '',
        description: ''
      },
    }
  },

  components: {
    Card,
    Form
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Poliklinik", route: "" },
      { title: "Daftar Poliklinik", route: "/polyclinic/list" },
      { title: "Tambah" },
    ])
  },

}

</script>